import VehicleService from '../services/VehicleService';
import UserService from '../services/UserService';
import POIService from '../services/POIService';
import OrderService from '../services/OrderService';
import ClientService from '../services/ClientService';
import NotificationService from '../services/NotificationService';
import ReportService from '../services/ReportService';
import ChatService from '../services/ChatService';
import UsersService from '../services/UsersService';
import DriversService from '../services/DriversService';
import AccessSchemaService from '../services/AccessSchemaService';
import DevicesService from '../services/DevicesService';
import LicenseService from '../services/LicenseService';
import OffersService from '../services/OffersService';
import GroupService from '../services/GroupService';
import AtlasService from '../services/AtlasService';
import PuescService from '../services/PuescService';

export default class ServicesManager {
    static instance = null;

    static getInstance() {
        if (!ServicesManager.instance) {
            ServicesManager.instance = new ServicesManager();
        }
        return ServicesManager.instance;
    }

    constructor() {
        this.services = null;
    }

    async initializeServices(connection, setUserIsLogged) {
        if (this.services) {
            return this.services;
        }

        // Wait for connection to be ready
        if (!connection.isConnectionReady()) {
            console.log('Connection not ready, waiting...');
            try {
                await connection.waitForConnection();
                console.log('Connection ready, initializing services');
            } catch (error) {
                console.error('Failed while waiting for connection:', error);
                return null;
            }
        }

        console.log('Creating services');
        this.services = {
            connection,
            vehicleService: new VehicleService(connection),
            userService: new UserService(connection, setUserIsLogged),
            poiService: new POIService(connection),
            orderService: new OrderService(connection),
            clientService: new ClientService(connection),
            notificationService: new NotificationService(connection),
            reportService: new ReportService(connection),
            chatService: new ChatService(connection),
            usersService: new UsersService(connection),
            driversService: new DriversService(connection),
            accessSchemaService: new AccessSchemaService(connection),
            devicesService: new DevicesService(connection),
            licenseService: new LicenseService(connection),
            offersService: new OffersService(connection),
            groupService: new GroupService(connection),
            atlasService: new AtlasService(connection),
            puescService: new PuescService(connection),
        };

        return this.services;
    }
}
