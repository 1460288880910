import React, {useState, useEffect, useCallback} from 'react';
import {useTranslation} from 'react-i18next';
import {toast} from 'react-toastify';

import {Loader} from '../../components/Loader/Loader';
import FormEditor from './parts/FormEditor';
import useServiceProvider from '../../utils/service';
import type {Column} from 'react-table';
import Table from '../../components/Table';
import FormPreviewEditor from './parts/FormPreviewEditor';
import {MainListHeader} from '../../components/MainListsHeader/MainListHeader';
import {confirmAlert} from 'react-confirm-alert';

interface FormField {
    label: string;
    description: string;
}

export interface PickupForm {
    id: number;
    name: string;
    client_id: number | null;
    fields: FormField[];
    default: boolean;
    created_at: string;
    updated_at: string;
}

const FormManager: React.FC = () => {
    const [t] = useTranslation(['Forms', 'common']);
    const {vehicleService} = useServiceProvider();
    const [loading, setLoading] = useState(true);
    const [forms, setForms] = useState<PickupForm[]>([]);
    const [selectedForm, setSelectedForm] = useState<PickupForm | null>(null);
    const [isEditorOpen, setIsEditorOpen] = useState(false);
    const [isPreviewOpen, setIsPreviewOpen] = useState(false);

    const tableColumns: Column<PickupForm>[] = [
        {
            Header: t('FORM_NAME'),
            accessor: 'name',
            width: 25,
        },
        {
            Header: t('FORM_TYPE'),
            accessor: 'default',
            width: 15,
            Cell: ({row}: any) =>
                row.original.client_id ? t('CUSTOM_FORM') : t('SYSTEM_FORM'),
        },
        {
            Header: t('LIST'),
            accessor: 'fields',
            Cell: ({value}) =>
                value
                    .map((item: FormField) => item.label)
                    .join(', ')
                    .slice(0, 250) + '...',
        },
    ];

    const loadForms = useCallback(() => {
        setLoading(true);
        vehicleService?.getPickupFormFields(
            (data: PickupForm[]) => {
                setForms(data);
                setLoading(false);
            },
            (error: string) => {
                toast.error(t('FORMS_LOADING_ERROR', {error: t(error)}));
                setLoading(false);
            },
        );
    }, [vehicleService, t]);

    useEffect(() => {
        loadForms();
    }, [loadForms]);

    const handleRowClick = (row: PickupForm) => {
        setSelectedForm(row);
        setIsPreviewOpen(true);
    };

    const handleAddForm = () => {
        setSelectedForm(null);
        setIsEditorOpen(true);
    };

    const handleDeleteForm = async (form: PickupForm | null) => {
        if (!form?.client_id && !form) {
            toast.error(t('CANNOT_DELETE_SYSTEM_FORM'));
            return;
        }

        confirmAlert({
            title: t('DELETE_FORM_CONFIRM_TITLE'),
            message: t('DELETE_FORM_CONFIRM_MESSAGE'),
            buttons: [
                {
                    label: t('common:YES'),
                    onClick: () => {
                        vehicleService.removePickupFormFields(
                            form.id,
                            () => {
                                loadForms();
                                toast.success(t('FORM_DELETED_SUCCESS'));
                            },
                            (reason: string) => {
                                toast.error(
                                    t('FORM_DELETE_ERROR', {error: t(reason)}),
                                );
                            },
                        );
                    },
                },
                {
                    label: t('common:NO'),
                },
            ],
        });
    };

    if (loading) {
        return <Loader />;
    }

    return (
        <div className="form-manager">
            <MainListHeader
                headerText={t('FORMS')}
                addText={t('NEW_FORM')}
                switchAddMode={handleAddForm}
            />
            <Table
                //@ts-ignore
                columns={tableColumns}
                data={forms}
                onRowClick={handleRowClick}
                defaultSortBy={[{id: 'name', desc: false}]}
            />
            <FormEditor
                isOpen={isEditorOpen}
                onClose={() => {
                    setIsEditorOpen(false);
                    setSelectedForm(null);
                    loadForms();
                }}
                initialData={selectedForm || undefined}
            />
            <FormPreviewEditor
                isOpen={isPreviewOpen}
                onClose={() => {
                    setIsPreviewOpen(false);
                    setSelectedForm(null);
                }}
                form={selectedForm || null}
                onEdit={() => {
                    setIsPreviewOpen(false);
                    setIsEditorOpen(true);
                }}
                deleteForm={() => {
                    handleDeleteForm(selectedForm);
                    setIsPreviewOpen(false);
                    setSelectedForm(null);
                }}
            />
        </div>
    );
};

export default FormManager;
