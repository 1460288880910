import React, {useEffect, useRef, useState} from 'react';
import {useTranslation} from 'react-i18next';
import DatePicker from 'react-datepicker';
import {toast} from 'react-toastify';
import Select, {ActionMeta, MultiValue} from 'react-select';
import moment from 'moment';
import {NumberField, TextField} from '../../../components/Input/Input';
import {IconInfo} from '../../../graphics/icons';
import useServiceProvider from '../../../utils/service';
import {multiSelectStyles} from '../../../utils/selectStyles';
import {phoneValidator} from '../../../utils/validator';
import {DATEPICKER_DATE_FORMAT} from '../../../utils/constants';
import type {NewVehicle, Vehicle} from '../../../utils/interfaces/vehicle';
import type {Device} from '../../../utils/interfaces/device';
import type {Driver} from '../../../utils/interfaces/driver';
import type {Group} from '../../../utils/interfaces/group';
import {App} from '../../../redux/reducers/rootReducer';
import RightPane from '../../../components/RightPane/RightPaneFunc';
import {PickupForm} from '../../FormList/FormManager';

interface VehicleFormData {
    name: string;
    fuel_consumption_avg: string;
    fuel_consumption_deviation: string;
    fuel_tank_capacity1: string;
    fuel_tank_capacity2: string;
    comment: string;
    device_id: string;
    driver_id: string;
    assigned_driver_id: string;
    phone_number: string;
    policy_date?: Date;
    inspection_date?: Date;
    diagnostic_date?: Date;
    inspection_distance?: number;
    pickup_form: string | null;
    form_required: boolean;
}

interface AddVehicleProps {
    isOpen: boolean;
    onClose: () => void;
    deviceList: Device[];
    driverList: Driver[];
    app: App;
    vehicleList: Vehicle[];
    groupList: Group[];
}

interface GroupOption {
    value: number;
    label: string;
}

interface SelectOption {
    value: string;
    label: string;
}

const initialVehicleData: VehicleFormData = {
    name: '',
    fuel_consumption_avg: '',
    fuel_consumption_deviation: '',
    fuel_tank_capacity1: '',
    fuel_tank_capacity2: '',
    comment: '',
    device_id: '',
    driver_id: '',
    assigned_driver_id: '',
    phone_number: '',
    policy_date: undefined,
    inspection_date: undefined,
    diagnostic_date: undefined,
    inspection_distance: undefined,
    pickup_form: '',
    form_required: false,
};

const AddVehicle: React.FC<AddVehicleProps> = ({
    onClose,
    isOpen,
    deviceList,
    driverList,
    app,
    vehicleList,
    groupList,
}) => {
    const {t} = useTranslation(['Vehicles', 'common']);
    const paneRef = useRef<HTMLDivElement>(null);
    const {groupService, vehicleService, driversService} = useServiceProvider();

    const [vehicleData, setVehicleData] =
        useState<VehicleFormData>(initialVehicleData);
    const [updateInProgress, setUpdateInProgress] = useState<boolean>(false);
    const [selectedDriver, setSelectedDriver] = useState<Driver | null>(null);
    const [selectedGroups, setSelectedGroups] = useState<number[]>([]);
    const [dataErrors, setDataErrors] = useState<string[]>([]);
    const [pickupForms, setPickupForms] = useState<SelectOption[]>([]);

    useEffect(() => {
        if (!vehicleData.assigned_driver_id || !driverList) {
            setSelectedDriver(null);
            return;
        }
        const driverId = parseInt(vehicleData.assigned_driver_id);
        setSelectedDriver(driverList.filter((d) => d.id === driverId)[0]);
    }, [vehicleData.assigned_driver_id, driverList]);

    useEffect(() => {
        vehicleService.getPickupFormFields((result: PickupForm[]) => {
            setPickupForms(
                result.map((form) => ({
                    value: form.id.toString(),
                    label: form.name,
                })),
            );
        });
    }, [vehicleService]);

    const isValidForm = (): boolean => {
        const {name, phone_number} = vehicleData;
        const errors: string[] = [];
        if (name.trim().length === 0) {
            errors.push('name');
        }
        if (
            phone_number &&
            phone_number.trim().length > 0 &&
            !phoneValidator(phone_number.trim())
        ) {
            errors.push('phone_number');
        }

        if (errors.length > 0) {
            setDataErrors(errors);
            return false;
        }
        setDataErrors([]);
        return true;
    };

    const createVehicle = (e: React.FormEvent) => {
        e.preventDefault();

        if (!isValidForm()) {
            return;
        }

        const {
            name,
            fuel_consumption_avg,
            fuel_consumption_deviation,
            fuel_tank_capacity1,
            fuel_tank_capacity2,
            comment,
            device_id,
            phone_number,
        } = vehicleData;

        const vd: NewVehicle = {
            name: name.trim(),
        };
        if (fuel_consumption_avg !== '') {
            vd.fuel_consumption_avg = parseFloat(fuel_consumption_avg);
        }
        if (fuel_consumption_deviation !== '') {
            vd.fuel_consumption_deviation =
                parseInt(fuel_consumption_deviation) / 100;
        }
        if (fuel_tank_capacity1 !== '') {
            vd.fuel_tank_capacity1 = parseInt(fuel_tank_capacity1);
        }
        if (fuel_tank_capacity2 !== '') {
            vd.fuel_tank_capacity2 = parseInt(fuel_tank_capacity2);
        }
        if (comment.trim().length === 0) {
            vd.comment = comment.trim();
        }
        if (phone_number && phone_number.trim().length > 0) {
            vd.phone_number = phone_number.trim();
        }

        console.debug(
            'AddVehicle::createVehicle() => vehicleData: %O; vd: %O',
            vehicleData,
            vd,
        );

        let vehicleId: number | null = null;

        const actions: Array<() => Promise<any>> = [
            () =>
                new Promise((resolve, reject) => {
                    vehicleService.createVehicle(
                        vd,
                        (result: {vehicle_id: number}) => {
                            vehicleId = result.vehicle_id;
                            return resolve(result);
                        },
                        reject,
                    );
                }),
        ];

        if (device_id !== '') {
            actions.push(
                () =>
                    new Promise((resolve, reject) => {
                        console.debug(
                            'AddVehicle::createVehicle() => Assign device #%i to: %O',
                            device_id,
                            vehicleId,
                        );
                        vehicleService.createRelation(
                            {
                                device_id: parseInt(device_id.toString()),
                                vehicle_id: vehicleId as number,
                                begin_ts: parseInt(moment().format('X')),
                                end_ts: null,
                            },
                            (result: unknown) => {
                                console.debug(
                                    'AddVehicle::createVehicle() => relation created: %O',
                                    result,
                                );
                                resolve(result);
                            },
                            (reason: unknown) => {
                                console.warn(
                                    'AddVehicle::createVehicle() => relation NOT created: %s',
                                    reason,
                                );
                                reject(reason);
                            },
                        );
                    }),
            );
            if (vehicleData.assigned_driver_id) {
                actions.push(
                    () =>
                        new Promise((resolve, reject) =>
                            driversService.assignToVehicle(
                                parseInt(vehicleData.assigned_driver_id),
                                vehicleId as number,
                                () => {
                                    console.debug('Assigned driver to vehicle');
                                },
                                () => {
                                    console.warn(
                                        'Failed to assign driver to vehicle',
                                    );
                                    reject(
                                        'Failed to assign driver to vehicle',
                                    );
                                },
                                parseInt(vehicleData?.assigned_driver_id),
                                vehicleData.form_required,
                            ),
                        ),
                );
            }

            const device = deviceList.find(
                (d: Device) => d.id === parseInt(device_id.toString()),
            );
            if (device) {
                const deviceId = parseInt(device_id);
                const newDeviceInfo = Object.assign(
                    {dotsens: {}},
                    device.info,
                    {
                        dotsens: {
                            diagDay: vehicleData.diagnostic_date,
                            inspectionDay: vehicleData.inspection_date,
                            polDay: vehicleData.policy_date,
                            inspectionDistance: vehicleData.inspection_distance,
                        },
                    },
                );
                actions.push(
                    () =>
                        new Promise((resolve, reject) => {
                            vehicleService.updateDeviceInfo(
                                deviceId,
                                newDeviceInfo,
                                resolve,
                                reject,
                            );
                        }),
                );
                actions.push(
                    () =>
                        new Promise((resolve, reject) => {
                            vehicleService.updateDeviceAlerts(
                                deviceId,
                                newDeviceInfo,
                                resolve,
                                reject,
                            );
                        }),
                );
            }
        }

        const onSuccess = () => {
            toast.success(t('VEHICLE_CREATED'));
            setVehicleData(initialVehicleData);
            onClose();
        };

        let p = Promise.resolve();
        for (let i = 0; i < actions.length; i++) {
            p = p.then(actions[i]);
        }
        p.then(() => {
            if (
                selectedGroups &&
                selectedGroups.length > 0 &&
                vehicleId !== null
            ) {
                const groupActions: Array<() => Promise<any>> = [];
                const groupsToUpdate = groupList
                    .filter((group) => selectedGroups.includes(group.id))
                    .map((group) => ({
                        id: group.id,
                        vehicles: [...group.vehicles, vehicleId as number],
                        devices:
                            device_id !== ''
                                ? [
                                      ...group.devices,
                                      parseInt(device_id.toString()),
                                  ]
                                : group.devices,
                    }));
                groupsToUpdate.forEach((groupToUpdate) => {
                    groupActions.push(
                        () =>
                            new Promise((resolve, reject) => {
                                groupService.updateGroup(
                                    groupToUpdate,
                                    resolve,
                                    reject,
                                );
                            }),
                    );
                });
                let p = Promise.resolve();
                for (let i = 0; i < groupActions.length; i++) {
                    p = p.then(groupActions[i]);
                }
                p.then(() => {
                    onSuccess();
                });
            } else {
                onSuccess();
            }
        })
            .catch((reason) => {
                toast.error(t('VEHICLE_CREATION_ERROR', {error: t(reason)}));
            })
            .finally(() => setUpdateInProgress(false));
    };

    const setVehicleDataField = (
        e:
            | React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
            | {target: {name: string, value: any}},
    ) => {
        let {name, value} = e.target;
        if (name === 'device_id' || name === 'assigned_driver_id') {
            value = value ? value : ''; // Keep as string, parse only when needed
        }
        setVehicleData({...vehicleData, [name]: value});
    };

    const vehicleDataFields = () => {
        return (
            <>
                <div className="group">
                    <TextField
                        id="vehicle_name"
                        name="name"
                        label={t('VEHICLE_NAME')}
                        value={vehicleData.name ?? ''}
                        onChange={setVehicleDataField}
                        required={true}
                        hasError={dataErrors.includes('name')}
                    />
                    <NumberField
                        id="fuel_consumption_avg"
                        name="fuel_consumption_avg"
                        label={t('FUEL_CONSUMPTION')}
                        value={vehicleData.fuel_consumption_avg ?? ''}
                        onChange={setVehicleDataField}
                        min={1}
                        max={9999.99}
                        step={0.01}
                    />
                    <NumberField
                        id="fuel_consumption_deviation"
                        name="fuel_consumption_deviation"
                        label={t('FUEL_CONSUMPTION_DEVIATION')}
                        value={vehicleData.fuel_consumption_deviation ?? ''}
                        onChange={setVehicleDataField}
                        min={1}
                        max={99999}
                        step={1}
                    />
                    <NumberField
                        id="fuel_tank_capacity1"
                        name="fuel_tank_capacity1"
                        label={t('FUEL_TANK_1')}
                        value={vehicleData.fuel_tank_capacity1 ?? ''}
                        onChange={setVehicleDataField}
                        min={0}
                        max={65536}
                        step={1}
                    />
                    <NumberField
                        id="fuel_tank_capacity2"
                        name="fuel_tank_capacity2"
                        label={t('FUEL_TANK_2')}
                        value={vehicleData.fuel_tank_capacity2 ?? ''}
                        onChange={setVehicleDataField}
                        min={0}
                        max={65536}
                        step={1}
                    />
                    <TextField
                        id="phone_number"
                        name="phone_number"
                        label={t('PHONE_NUMBER')}
                        value={vehicleData.phone_number ?? ''}
                        onChange={setVehicleDataField}
                        hasError={dataErrors.includes('phone_number')}
                        hint={t('common:PHONE_NUMBER_HINT')}
                    />
                    <TextField
                        id="comment"
                        name="comment"
                        label={t('COMMENT')}
                        value={vehicleData.comment ?? ''}
                        onChange={setVehicleDataField}
                    />
                </div>
                <div className="group">
                    <div key="assigned_device" className="field">
                        <label htmlFor="assigned_device">
                            {t('ASSIGNED_DEVICE')}
                        </label>
                        <Select
                            name="device_id"
                            id="assigned_device"
                            placeholder={t('DEVICE_NOT_ASSIGNED', {
                                ns: 'Vehicles',
                            })}
                            value={
                                vehicleData.device_id
                                    ? {
                                          value: vehicleData.device_id.toString(),
                                          label:
                                              deviceList.find(
                                                  (device) =>
                                                      device.id.toString() ===
                                                      vehicleData.device_id,
                                              )?.serial_num ||
                                              t('UNKNOWN_DEVICE', {
                                                  ns: 'Vehicles',
                                              }),
                                      }
                                    : null
                            }
                            onChange={(newValue) => {
                                if (newValue?.value === '') {
                                    setVehicleData({
                                        ...vehicleData,
                                        device_id: '',
                                        assigned_driver_id: '',
                                        pickup_form: null,
                                        form_required: false,
                                    });
                                } else {
                                    setVehicleDataField({
                                        target: {
                                            name: 'device_id',
                                            value: newValue?.value,
                                        },
                                    });
                                }
                            }}
                            options={[
                                {
                                    value: '',
                                    label:
                                        t('DEVICE_NOT_ASSIGNED', {
                                            ns: 'Vehicles',
                                        }) || 'Device not assigned',
                                },
                                ...deviceList.map((device) => ({
                                    value: device.id.toString(),
                                    label: device.serial_num,
                                    isDisabled: !!vehicleList.find(
                                        (v: Vehicle) =>
                                            v.device_id === device.id,
                                    ),
                                })),
                            ]}
                            styles={multiSelectStyles}
                        />
                    </div>
                    {app.variant === 'fm' && (
                        <>
                            <div key="assigned_driver" className="field">
                                <label htmlFor="assigned_driver">
                                    {t('ASSIGNED_DRIVER')}
                                </label>
                                <Select
                                    name="assigned_driver_id"
                                    id="assigned_driver"
                                    placeholder={t('DRIVER_NOT_ASSIGNED', {
                                        ns: 'Vehicles',
                                    })}
                                    value={
                                        vehicleData.assigned_driver_id
                                            ? {
                                                  value: vehicleData.assigned_driver_id.toString(),
                                                  label: (() => {
                                                      const driver =
                                                          driverList.find(
                                                              (d) =>
                                                                  d.id.toString() ===
                                                                  vehicleData.assigned_driver_id,
                                                          );
                                                      return driver
                                                          ? `${driver.first_name} ${driver.last_name}`
                                                          : t(
                                                                'UNKNOWN_DRIVER',
                                                                {
                                                                    ns: 'Vehicles',
                                                                },
                                                            );
                                                  })(),
                                              }
                                            : null
                                    }
                                    isDisabled={!vehicleData.device_id}
                                    onChange={(newValue) => {
                                        if (newValue?.value) {
                                            setVehicleDataField({
                                                target: {
                                                    name: 'assigned_driver_id',
                                                    value:
                                                        newValue?.value || '',
                                                },
                                            });
                                        }
                                    }}
                                    options={[
                                        {
                                            value: '',
                                            label:
                                                t('DRIVER_NOT_ASSIGNED', {
                                                    ns: 'Vehicles',
                                                }) || 'Driver not assigned',
                                        },
                                        ...driverList
                                            .filter(
                                                (d) =>
                                                    d.active && d.user_active,
                                            )
                                            .sort((a, b) =>
                                                (
                                                    a.first_name +
                                                    ' ' +
                                                    a.last_name
                                                ).localeCompare(
                                                    b.first_name +
                                                        ' ' +
                                                        b.last_name,
                                                ),
                                            )
                                            .map((driver: Driver) => ({
                                                value: driver.id.toString(),
                                                label:
                                                    driver.first_name +
                                                    ' ' +
                                                    driver.last_name,
                                            })),
                                    ]}
                                    styles={multiSelectStyles}
                                />
                            </div>
                        </>
                    )}
                    {selectedDriver && selectedDriver.vehicle_id && (
                        <span className="hint">
                            <IconInfo />
                            <p>{t('ASSIGNED_DRIVER_INFO')}</p>
                        </span>
                    )}
                    <div className="field">
                        <label htmlFor="pickup_form">{t('PICKUP_FORM')}</label>
                        <Select
                            name="pickup_form"
                            value={
                                vehicleData.pickup_form
                                    ? pickupForms.find(
                                          (option) =>
                                              option.value ===
                                              vehicleData.pickup_form,
                                      ) || null
                                    : null
                            }
                            isDisabled={!vehicleData.assigned_driver_id}
                            onChange={(newValue: SelectOption | null) => {
                                if (newValue) {
                                    if (newValue.value === 'none') {
                                        setVehicleData({
                                            ...vehicleData,
                                            pickup_form: null,
                                            form_required: false,
                                        });
                                    } else {
                                        setVehicleData({
                                            ...vehicleData,
                                            pickup_form: newValue.value,
                                            form_required:
                                                newValue.value !== 'none',
                                        });
                                    }
                                }
                            }}
                            placeholder={
                                t('PICKUP_FORM_NOT_ASSIGNED', {
                                    ns: 'Vehicles',
                                }) || 'Not assigned'
                            }
                            options={[
                                {
                                    value: 'none',
                                    label:
                                        t('PICKUP_FORM_NOT_ASSIGNED', {
                                            ns: 'Vehicles',
                                        }) || 'Not assigned',
                                },
                                ...pickupForms,
                            ]}
                            styles={multiSelectStyles}
                        />
                    </div>

                    {app.variant === 'fm' && groupList && (
                        <div className="field">
                            <label htmlFor="group_id">
                                {t('ASSIGN_TO_GROUP')}
                            </label>
                            <Select
                                isMulti
                                name="group_id"
                                placeholder={t('SELECT_GROUPS')}
                                defaultValue={[]}
                                options={groupList
                                    .sort((a, b) =>
                                        a.name.localeCompare(b.name),
                                    )
                                    .map((group) => ({
                                        value: group.id,
                                        label: group.name,
                                    }))}
                                onChange={(
                                    selected: MultiValue<GroupOption>,
                                    actionMeta: ActionMeta<GroupOption>,
                                ) => {
                                    setSelectedGroups(
                                        selected?.map((item) => item.value) ??
                                            [],
                                    );
                                }}
                                styles={multiSelectStyles}
                            />
                        </div>
                    )}
                </div>
                {vehicleData.device_id && (
                    <div className="group">
                        <div key="policy_date" className="field">
                            <label htmlFor="policy_date">
                                {t('POLICY_DATE')}
                            </label>
                            <div>
                                <DatePicker
                                    id="policy_date"
                                    locale={'pl'}
                                    autoComplete={'off'}
                                    selected={vehicleData.policy_date}
                                    onChange={(date: Date) => {
                                        setVehicleData({
                                            ...vehicleData,
                                            policy_date: date,
                                        });
                                    }}
                                    dateFormat={DATEPICKER_DATE_FORMAT}
                                    calendarStartDay={1}
                                />
                            </div>
                        </div>
                        <div key="inspection_date" className="field">
                            <label htmlFor="inspection_date">
                                {t('INSPECTION_DATE')}
                            </label>
                            <div>
                                <DatePicker
                                    id="inspection_date"
                                    locale={'pl'}
                                    autoComplete={'off'}
                                    selected={vehicleData.inspection_date}
                                    onChange={(date: Date) => {
                                        setVehicleData({
                                            ...vehicleData,
                                            inspection_date: date,
                                        });
                                    }}
                                    dateFormat={DATEPICKER_DATE_FORMAT}
                                    calendarStartDay={1}
                                />
                            </div>
                        </div>
                        <div key="diagnostic_date" className="field">
                            <label htmlFor="diagnostic_date">
                                {t('DIAGNOSTIC_DATE')}
                            </label>
                            <div>
                                <DatePicker
                                    id="diagnostic_date"
                                    locale={'pl'}
                                    autoComplete={'off'}
                                    selected={vehicleData.diagnostic_date}
                                    onChange={(date: Date) => {
                                        setVehicleData({
                                            ...vehicleData,
                                            diagnostic_date: date,
                                        });
                                    }}
                                    dateFormat={DATEPICKER_DATE_FORMAT}
                                    calendarStartDay={1}
                                />
                            </div>
                        </div>
                    </div>
                )}
            </>
        );
    };

    return (
        <form id="vehicle-add-form" onSubmit={createVehicle}>
            <RightPane
                isOpen={isOpen}
                id="vehicle-add"
                className="vehicle-add panel-right-form panel-right-entity-details"
                ref={paneRef}
                title={t('CREATE_VEHICLE')}
                onClose={onClose}
                body={vehicleDataFields}
                footer={() => (
                    <button disabled={updateInProgress} className="button save">
                        {t('common:SAVE')}
                    </button>
                )}
            />
        </form>
    );
};

export default AddVehicle;
