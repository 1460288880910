import React, {useState, useEffect} from 'react';
import {useTranslation} from 'react-i18next';
import {toast} from 'react-toastify';
import {TextField} from '../../../components/Input/Input';
import RightPane from '../../../components/RightPane/RightPaneFunc';
import {IconArchive, IconPlus} from '../../../graphics/icons';

import useServiceProvider from '../../../utils/service';
import {PickupForm} from '../FormManager';
import './FormEditor.scss';

interface FormField {
    label: string;
    description: string;
}

interface FormData {
    name: string;
    fields: FormField[];
    default: boolean;
}

interface FormEditorProps {
    onClose: () => void;
    initialData?: PickupForm;
    isOpen: boolean;
}

const EMPTY_FORM: FormData = {
    name: '',
    fields: [{label: '', description: ''}],
    default: false,
};

const FormEditor: React.FC<FormEditorProps> = ({
    onClose,
    initialData,
    isOpen,
}) => {
    const [t] = useTranslation(['Forms', 'common']);
    const {vehicleService} = useServiceProvider();
    const [loading, setLoading] = useState(true);
    const [formData, setFormData] = useState<FormData>(EMPTY_FORM);

    useEffect(() => {
        if (initialData) {
            setFormData({
                name: initialData.name,
                fields: initialData.fields,
                default: initialData.default,
            });
        }
        setLoading(false);
    }, [initialData]);

    const handleFieldChange = (index: number, field: Partial<FormField>) => {
        const updatedFields = [...formData.fields];
        updatedFields[index] = {...updatedFields[index], ...field};
        setFormData({...formData, fields: updatedFields});
    };

    const validateForm = () => {
        if (!formData.name.trim()) {
            toast.error(t('FORM_NAME_REQUIRED'));
            return false;
        }

        const hasEmptyFields = formData.fields.some(
            (field) => !field.label.trim() || !field.description.trim(),
        );

        if (hasEmptyFields) {
            toast.error(t('FORM_FIELDS_REQUIRED'));
            return false;
        }

        return true;
    };

    const handleSave = async () => {
        if (!validateForm()) return;

        const onSuccess = () => {
            toast.success(t(initialData ? 'FORM_UPDATED' : 'FORM_CREATED'));
            onClose();
            setFormData(EMPTY_FORM);
        };

        const onError = (reason: string) => {
            toast.error(
                t(initialData ? 'FORM_UPDATE_ERROR' : 'FORM_CREATE_ERROR', {
                    error: t(reason),
                }),
            );
        };

        if (initialData) {
            await vehicleService?.updatePickupFormFields(
                {id: initialData.id, ...formData},
                onSuccess,
                onError,
            );
        } else {
            await vehicleService?.createPickupFormFields(
                formData,
                onSuccess,
                onError,
            );
        }
    };

    const renderField = (field: FormField, index: number) => (
        <div key={index} className="form-editor__field">
            <TextField
                placeholder={t('LABEL')}
                value={field.label}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    handleFieldChange(index, {label: e.target.value})
                }
            />
            <TextField
                placeholder={t('DESCRIPTION')}
                value={field.description}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    handleFieldChange(index, {description: e.target.value})
                }
            />
            <button
                className="remove-icon"
                onClick={() => {
                    const updatedFields = formData.fields.filter(
                        (_, i) => i !== index,
                    );
                    setFormData({...formData, fields: updatedFields});
                }}
            >
                <IconArchive />
            </button>
        </div>
    );

    if (loading) {
        return <div className="loading">{t('COMMON:LOADING')}</div>;
    }

    return (
        <RightPane
            isOpen={isOpen}
            onClose={onClose}
            title={t('FORM_EDITOR')}
            id="form-editor"
            className="panel-right-form"
            body={() => (
                <div className="form-editor">
                    <TextField
                        label={t('FORM_NAME')}
                        value={formData.name}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                            setFormData({...formData, name: e.target.value})
                        }
                    />

                    <div className="form-editor__section">
                        <h3>{t('LIST_TO_CHECK')}</h3>
                        <div className="form-editor__fields">
                            {formData.fields.map(renderField)}
                        </div>
                        <div className="form-editor__add-button">
                            <button
                                className="add-icon"
                                onClick={() =>
                                    setFormData({
                                        ...formData,
                                        fields: [
                                            ...formData.fields,
                                            {label: '', description: ''},
                                        ],
                                    })
                                }
                            >
                                <IconPlus />
                            </button>
                        </div>
                    </div>

                    <div className="form-editor__actions">
                        <button className="button" onClick={onClose}>
                            {t('common:CANCEL')}
                        </button>
                        <button className="button save" onClick={handleSave}>
                            {initialData ? t('common:SAVE') : t('Forms:ADD')}
                        </button>
                    </div>
                </div>
            )}
        />
    );
};

export default FormEditor;
