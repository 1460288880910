import React, {useEffect, forwardRef} from 'react';
import ReactTooltip from 'react-tooltip';
import i18n from '../../i18n/i18n';

import './RightPane.scss';

interface RightPaneProps {
    id?: string;
    className?: string;
    title: string;
    isOpen: boolean;
    onClose: () => void;
    body?: () => React.ReactNode;
    footer?: () => React.ReactNode;
    buttons?: () => React.ReactNode;
}

const RightPane = forwardRef<HTMLDivElement, RightPaneProps>(({
    id,
    className = '',
    title,
    isOpen,
    onClose,
    body,
    footer,
    buttons,
}, ref) => {
    if (!id) {
        throw new Error('RightPane requires "id" property to be set');
    }

    useEffect(() => {
        // Rebuild tooltips after update
        const timer = setTimeout(() => {
            ReactTooltip.rebuild();
        }, 0);

        return () => clearTimeout(timer);
    });

    return (
        <div
            ref={ref}
            id={id}
            className={'panel-right-module' + (isOpen ? ' visible' : '')}
        >
            <span className="coat" onClick={onClose} />
            <div className={'panel-right ' + className}>
                <div className="header">
                    <span
                        className={'close'}
                        data-tip={i18n.t('CLOSE')}
                        data-place={'left'}
                        onClick={onClose}
                    >
                        &times;
                    </span>
                    <span className="title">{title}</span>
                    {buttons && buttons()}
                </div>
                <div className="body">{body && body()}</div>
                {footer && <div className="footer">{footer()}</div>}
            </div>
        </div>
    );
});

export default RightPane;
