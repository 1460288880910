import React, {MutableRefObject, useEffect, useRef, useState} from 'react';
import {useTranslation} from 'react-i18next';
import DatePicker from 'react-datepicker';
import {toast} from 'react-toastify';
import moment from 'moment';

import useServiceProvider from '../../../utils/service';
import RightPane from '../../../components/RightPane/RightPane';
import {Loader} from '../../../components/Loader/Loader';
import {
    CheckboxField,
    NumberField,
    TextField,
} from '../../../components/Input/Input';
import {IconInfo} from '../../../graphics/icons';
import {phoneValidator} from '../../../utils/validator';
import {DATEPICKER_DATE_FORMAT} from '../../../utils/constants';
import type {UpdateVehicle, Vehicle} from '../../../utils/interfaces/vehicle';
import type {Driver} from '../../../utils/interfaces/driver';

/**
 *
 * @param onHide {Function}
 * @param currentVehicle {Vehicle}
 * @param app {App}
 * @param driverList {Driver[]}
 * @param deviceList {Device[]}
 * @param vehicleList {Vehicle[]}
 * @param vehicleService {VehicleService}
 * @param driversService {DriversService}
 * @param assignedDriver {Driver}
 * @returns {JSX.Element}
 * @constructor
 */
const EditVehicle = ({
    onHide,
    currentVehicle,
    app,
    driverList,
    deviceList,
    vehicleList,
    vehicleService,
    driversService,
    assignedDriver,
}) => {
    const {t} = useTranslation(['Vehicles', 'common']);

    const paneRef: MutableRefObject<RightPane> = useRef(null);

    const {devicesService, puescService} = useServiceProvider();
    const initialVehicleData = {
        vehicle_id: currentVehicle.vehicle_id,
        name: currentVehicle.name || '',
        fuel_consumption_avg: currentVehicle.fuel_consumption_avg || '',
        fuel_consumption_deviation:
            currentVehicle.fuel_consumption_deviation || '',
        fuel_tank_capacity1: currentVehicle.fuel_tank_capacity1 || '',
        fuel_tank_capacity2: currentVehicle.fuel_tank_capacity2 || '',
        comment: currentVehicle.comment || '',
        device_id: currentVehicle.device_id || '',
        driver_id: currentVehicle.driver_id || '',
        assigned_driver_id: assignedDriver.id || '',
        phone_number: currentVehicle.phone_number || '',
        policy_date: currentVehicle.policy_date || undefined,
        inspection_date: currentVehicle.inspection_date || undefined,
        diagnostic_date: currentVehicle.diagnostic_date || undefined,
        inspection_distance: currentVehicle.inspection_distance || undefined,
        puesc_etoll_active: currentVehicle.puesc_etoll_active || false,
        puesc_sent_active: currentVehicle.puesc_sent_active || false,
    };

    const [vehicleData: Vehicle, setVehicleData: Function<Vehicle>] =
        useState(initialVehicleData);
    const [updateInProgress: boolean, setUpdateInProgress: Function<boolean>] =
        useState(false);
    const [dataErrors: string[], setDataErrors: Function<string[]>] = useState(
        [],
    );
    const [selectedDriver: Driver, setSelectedDriver: Function<Driver>] =
        useState(null);
    const [
        pickupForms: SelectOption[],
        setPickupForms: Function<SelectOption[]>,
    ] = useState([]);

    const setVehicleDataField = (e) => {
        let {name, value} = e.target;
        if (name === 'device_id' || name === 'assigned_driver_id') {
            value = value ? parseInt(value) : '';
        }
        if (name === 'puesc_etoll_active' || name === 'puesc_sent_active') {
            value = value === 'true';
        }

        // Clear pickup form and assigned driver when device changes
        if (name === 'device_id') {
            setVehicleData((prev) => ({
                ...prev,
                [name]: value,
                pickup_form: null,
                form_required: false,
                assigned_driver_id: null,
            }));
            setSelectedDriver(null);
        } else if (name === 'pickup_form') {
            setVehicleData((prev) => ({
                ...prev,
                [name]: value,
                form_required: value !== '' && value !== null,
            }));
        } else {
            setVehicleData((prev) => ({
                ...prev,
                [name]: value,
            }));
        }
    };

    useEffect(() => {
        if (!vehicleData.assigned_driver_id || !driverList) {
            setSelectedDriver(null);
            return;
        }
        setSelectedDriver(
            driverList.filter(
                (d) => d.id === vehicleData.assigned_driver_id,
            )[0],
        );
    }, [vehicleData.assigned_driver_id, driverList]);

    useEffect(() => {
        vehicleService.getPickupFormFields((result: PickupForm[]) => {
            setPickupForms(
                result.map((form) => ({
                    value: form.id.toString(),
                    label: form.name,
                })),
            );
        });
    }, [vehicleService]);

    const isValidForm = () => {
        const {name, phone_number} = vehicleData;
        const errors = [];
        if (name.trim().length === 0) {
            errors.push('name');
        }
        if (
            phone_number &&
            phone_number.trim().length > 0 &&
            !phoneValidator(phone_number.trim())
        ) {
            errors.push('phone_number');
        }

        if (errors.length > 0) {
            setDataErrors(errors);
            return false;
        }
        setDataErrors([]);
        return true;
    };

    const updateVehicle = (e: Event) => {
        e.preventDefault();

        if (!isValidForm()) {
            return;
        }

        setUpdateInProgress(true);

        const {
            vehicle_id,
            name,
            fuel_consumption_avg,
            fuel_consumption_deviation,
            fuel_tank_capacity1,
            fuel_tank_capacity2,
            comment,
            phone_number,
        } = vehicleData;

        const vd: UpdateVehicle = {
            vehicle_id: vehicle_id,
            name: name,
            fuel_consumption_avg:
                fuel_consumption_avg !== '' && fuel_consumption_avg !== null
                    ? parseFloat(fuel_consumption_avg)
                    : null,
            fuel_consumption_deviation:
                fuel_consumption_deviation !== '' &&
                fuel_consumption_deviation !== null
                    ? parseInt(fuel_consumption_deviation) / 100
                    : null,
            fuel_tank_capacity1:
                fuel_tank_capacity1 !== '' &&
                fuel_tank_capacity1 !== null &&
                fuel_tank_capacity1 !== '0'
                    ? parseInt(fuel_tank_capacity1)
                    : null,
            fuel_tank_capacity2:
                fuel_tank_capacity2 !== '' &&
                fuel_tank_capacity2 !== null &&
                fuel_tank_capacity2 !== '0'
                    ? parseInt(fuel_tank_capacity2)
                    : null,
            comment: comment,
            phone_number: phone_number,
        };
        console.debug(
            'EditVehicle::updateVehicle() => vehicleData: %O; vd: %O',
            vehicleData,
            vd,
        );

        let p = Promise.resolve();
        let actions = [];
        actions.push(
            () =>
                new Promise((resolve, reject) => {
                    vehicleService.updateVehicle(vd, resolve, reject);
                }),
        );

        /* Change device info about alarms */
        const datesChanged =
            vehicleData.inspection_date?.getTime() !==
                currentVehicle.inspection_date?.getTime() ||
            vehicleData.diagnostic_date?.getTime() !==
                currentVehicle.diagnostic_date?.getTime() ||
            vehicleData.policy_date?.getTime() !==
                currentVehicle.policy_date?.getTime() ||
            vehicleData.inspection_distance !==
                currentVehicle.inspection_distance;
        if (vehicleData.device_id !== '' && datesChanged) {
            const oldDevice = deviceList.find(
                (d) => d.id === parseInt(currentVehicle.device_id),
            );

            if (oldDevice) {
                const oldDeviceInfo = Object.assign(
                    {dotsens: {}},
                    oldDevice?.info,
                    {
                        dotsens: {
                            diagDay: vehicleData.diagnostic_date,
                            inspectionDay: vehicleData.inspection_date,
                            inspectionDistance: vehicleData.inspection_distance,
                            polDay: vehicleData.policy_date,
                        },
                    },
                );

                actions.push(
                    () =>
                        new Promise((resolve, reject) => {
                            vehicleService.updateDeviceInfo(
                                oldDevice.id,
                                oldDeviceInfo,
                                resolve,
                                reject,
                            );
                        }),
                );
                actions.push(
                    () =>
                        new Promise((resolve, reject) => {
                            vehicleService.updateDeviceAlerts(
                                oldDevice.id,
                                oldDeviceInfo,
                                resolve,
                                reject,
                            );
                        }),
                );
            }
        }

        /* device is changed, clear dates, alarms && update device<=>vehicle relations */
        if (currentVehicle.device_id !== vehicleData.device_id) {
            /* create new device if recent is null */
            if (currentVehicle.device_id === null && vehicleData.device_id) {
                actions.push(
                    () =>
                        new Promise((resolve, reject) => {
                            console.log(
                                'EditVehicle::updateVehicle() => vehicleService.createDeviceToVehicle()',
                            );
                            vehicleService.createRelation(
                                {
                                    device_id: vehicleData.device_id,
                                    vehicle_id: currentVehicle.vehicle_id,
                                    begin_ts: parseInt(moment().format('X')),
                                    end_ts: null,
                                },
                                (result) => {
                                    console.debug(
                                        'EditVehicle::updateVehicle() => relation created: %O',
                                        result,
                                    );
                                    resolve();
                                },
                                (reason) => {
                                    console.warn(
                                        'EditVehicle::updateVehicle() => relation NOT created: %s',
                                        reason,
                                    );
                                    reject(reason);
                                },
                            );
                        }),
                );
            }
            /* end relation with old device and relation with null device */
            if (
                currentVehicle.device_id !== null &&
                vehicleData.device_id === ''
            ) {
                actions.push(
                    () =>
                        new Promise((resolve, reject) => {
                            console.log(
                                'EditVehicle::updateVehicle() => vehicleService.endDeviceToVehicle()',
                            );
                            vehicleService.endDeviceToVehicle(
                                currentVehicle.vehicle_id,
                                (result) => {
                                    console.debug(
                                        'EditVehicle::updateVehicle() => relation updated: %O',
                                        result,
                                    );
                                    resolve();
                                },
                                (reason) => {
                                    console.warn(
                                        'EditVehicle::updateVehicle() => relation NOT updated: %s',
                                        reason,
                                    );
                                    reject(reason);
                                },
                            );
                        }),
                );
            }
            /* change device */
            if (
                currentVehicle.device_id !== vehicleData.device_id &&
                vehicleData.device_id !== '' &&
                !actions.some((action) =>
                    action.toString().includes('createDeviceToVehicle'),
                ) // Skip if we're already creating a new relation
            ) {
                actions.push(
                    () =>
                        new Promise((resolve, reject) =>
                            vehicleService.changeDeviceToVehicle(
                                {
                                    vehicle_id: currentVehicle.vehicle_id,
                                    new_device_id: vehicleData.device_id,
                                },
                                (result) => {
                                    console.debug(
                                        'EditVehicle::updateVehicle() => relation updated: %O',
                                        result,
                                    );
                                    resolve();
                                },
                                (reason) => {
                                    console.warn(
                                        'EditVehicle::updateVehicle() => relation NOT updated: %s',
                                        reason,
                                    );
                                    reject(reason);
                                },
                            ),
                        ),
                );
            }
        }

        if (assignedDriver.id !== vehicleData.assigned_driver_id) {
            if (assignedDriver.id) {
                actions.push(
                    () =>
                        new Promise((resolve, reject) =>
                            driversService.unassignFromVehicle(
                                currentVehicle,
                                resolve,
                                reject,
                            ),
                        ),
                );
            }
            if (vehicleData.assigned_driver_id) {
                actions.push(
                    () =>
                        new Promise((resolve, reject) =>
                            driversService.assignToVehicle(
                                vehicleData.assigned_driver_id,
                                currentVehicle.vehicle_id,
                                () => {
                                    // Update the vehicle data after driver assignment
                                    vehicleService.getVehicles(
                                        {
                                            filter_vehicle_id:
                                                currentVehicle.vehicle_id,
                                        },
                                        resolve,
                                        reject,
                                    );
                                },
                                reject,
                                parseInt(vehicleData?.pickup_form),
                                vehicleData.form_required,
                            ),
                        ),
                );
            }
        }

        if (currentVehicle.puesc_register_state === 'registered') {
            if (
                currentVehicle.puesc_contract_etoll_id !== null &&
                vehicleData.puesc_etoll_active !==
                    currentVehicle.puesc_etoll_active
            ) {
                if (vehicleData.puesc_etoll_active) {
                    actions.push(
                        () =>
                            new Promise((resolve, reject) =>
                                puescService.activateEtoll(
                                    currentVehicle.vehicle_id,
                                    resolve,
                                    reject,
                                ),
                            ),
                    );
                } else {
                    actions.push(
                        () =>
                            new Promise((resolve, reject) =>
                                puescService.deactivateEtoll(
                                    currentVehicle.vehicle_id,
                                    resolve,
                                    reject,
                                ),
                            ),
                    );
                }
            }
            if (
                currentVehicle.puesc_contract_sent_id !== null &&
                vehicleData.puesc_sent_active !==
                    currentVehicle.puesc_sent_active
            ) {
                if (vehicleData.puesc_sent_active) {
                    actions.push(
                        () =>
                            new Promise((resolve, reject) =>
                                puescService.activateSent(
                                    currentVehicle.vehicle_id,
                                    resolve,
                                    reject,
                                ),
                            ),
                    );
                } else {
                    actions.push(
                        () =>
                            new Promise((resolve, reject) =>
                                puescService.deactivateSent(
                                    currentVehicle.vehicle_id,
                                    resolve,
                                    reject,
                                ),
                            ),
                    );
                }
            }
        }

        console.debug(
            'EditVehicle::updateVehicle() => actions to be executed: ',
            actions.length,
        );

        for (let i = 0; i < actions.length; i++) {
            p = p.then(actions[i]);
            // p.then(() => new Promise((resolve, reject) => { actions[i](() => { resolve(); }, () => { reject(); }); }));
        }
        p.then(
            () =>
                new Promise((resolve) => {
                    devicesService.getDevices();
                    vehicleService.getVehicles();
                    toast.success(t('VEHICLE_UPDATED'));
                    paneRef.current.hideComponent();
                    resolve();
                }),
        )
            .catch((reason) => {
                toast.error(t('VEHICLE_UPDATE_ERROR', {error: t(reason)}));
            })
            .finally(() => {
                setUpdateInProgress(false);
            });
    };

    const vehicleDataFields = () => {
        return (
            <>
                <div className="group">
                    <TextField
                        id="vehicle_name"
                        name="name"
                        label={t('VEHICLE_NAME')}
                        value={vehicleData.name ?? ''}
                        onChange={setVehicleDataField}
                        required={true}
                        hasError={dataErrors.includes('name')}
                    />
                    <NumberField
                        id="fuel_consumption_avg"
                        name="fuel_consumption_avg"
                        label={t('FUEL_CONSUMPTION')}
                        value={vehicleData.fuel_consumption_avg ?? ''}
                        onChange={setVehicleDataField}
                        min="1"
                        max="9999.99"
                        step="0.01"
                    />
                    <NumberField
                        id="fuel_consumption_deviation"
                        name="fuel_consumption_deviation"
                        label={t('FUEL_CONSUMPTION_DEVIATION')}
                        value={vehicleData.fuel_consumption_deviation ?? ''}
                        onChange={setVehicleDataField}
                        min="1"
                        max="99999"
                        step="1"
                    />
                    <NumberField
                        id="fuel_tank_capacity1"
                        name="fuel_tank_capacity1"
                        label={t('FUEL_TANK_1')}
                        value={vehicleData.fuel_tank_capacity1 ?? ''}
                        onChange={setVehicleDataField}
                        min="0"
                        max="65536"
                        step="1"
                    />
                    <NumberField
                        id="fuel_tank_capacity2"
                        name="fuel_tank_capacity1"
                        label={t('FUEL_TANK_2')}
                        value={vehicleData.fuel_tank_capacity2 ?? ''}
                        onChange={setVehicleDataField}
                        min="0"
                        max="65536"
                        step="1"
                    />
                    <TextField
                        id="phone_number"
                        name="phone_number"
                        label={t('PHONE_NUMBER')}
                        value={vehicleData.phone_number ?? ''}
                        onChange={setVehicleDataField}
                        hasError={dataErrors.includes('phone_number')}
                        hint={t('common:PHONE_NUMBER_HINT')}
                    />
                    <TextField
                        id="comment"
                        name="comment"
                        label={t('COMMENT')}
                        value={vehicleData.comment ?? ''}
                        onChange={setVehicleDataField}
                    />
                </div>
                <div className="group">
                    <div key="assigned_device" className="field">
                        <label htmlFor="assigned_device">
                            {t('ASSIGNED_DEVICE')}
                        </label>
                        <select
                            name="device_id"
                            id="assigned_device"
                            value={vehicleData.device_id || ''}
                            onChange={setVehicleDataField}
                        >
                            <option value="" key="none">
                                {t('DEVICE_NOT_ASSIGNED')}
                            </option>
                            {deviceList.map((device) => (
                                <option
                                    key={'device_' + device.id}
                                    value={device.id ?? ''}
                                    disabled={
                                        !!vehicleList.find(
                                            (v: Vehicle) =>
                                                v.device_id === device.id,
                                        )
                                    }
                                >
                                    {device.serial_num}
                                </option>
                            ))}
                        </select>
                    </div>
                    {app.variant === 'fm' && (
                        <>
                            <div key="assigned_driver" className="field">
                                <label htmlFor="assigned_driver">
                                    {t('ASSIGNED_DRIVER')}
                                </label>
                                <select
                                    name="assigned_driver_id"
                                    id="assigned_driver"
                                    value={vehicleData.assigned_driver_id || ''}
                                    disabled={!vehicleData.device_id}
                                    onChange={setVehicleDataField}
                                >
                                    <option value="" key="none">
                                        {t('DRIVER_NOT_ASSIGNED')}
                                    </option>
                                    {driverList
                                        .filter(
                                            (d) => d.active && d.user_active,
                                        )
                                        .sort((a, b) =>
                                            (
                                                a.first_name +
                                                ' ' +
                                                a.last_name
                                            ).localeCompare(
                                                b.first_name +
                                                    ' ' +
                                                    b.last_name,
                                            ),
                                        )
                                        .map((driver) => (
                                            <option
                                                key={'driver_' + driver.id}
                                                value={driver.id}
                                            >
                                                {driver.first_name +
                                                    ' ' +
                                                    driver.last_name}
                                            </option>
                                        ))}
                                </select>
                            </div>
                        </>
                    )}
                    {vehicleData.assigned_driver_id !== assignedDriver.id && (
                        <div className="field">
                            <label htmlFor="pickup_form">
                                {t('PICKUP_FORM')}
                            </label>
                            <select
                                name="pickup_form"
                                value={vehicleData.pickup_form || ''}
                                disabled={!vehicleData.assigned_driver_id}
                                onChange={setVehicleDataField}
                                placeholder={
                                    t('PICKUP_FORM_NOT_ASSIGNED', {
                                        ns: 'Vehicles',
                                    }) || 'Not assigned'
                                }
                            >
                                <option value="none">
                                    {t('PICKUP_FORM_NOT_ASSIGNED', {
                                        ns: 'Vehicles',
                                    }) || 'Not assigned'}
                                </option>
                                {pickupForms.map((form) => (
                                    <option key={form.value} value={form.value}>
                                        {form.label}
                                    </option>
                                ))}
                            </select>
                        </div>
                    )}
                    {selectedDriver && selectedDriver.vehicle_id && (
                        <span className="hint">
                            <IconInfo />
                            <p>{t('ASSIGNED_DRIVER_INFO')}</p>
                        </span>
                    )}
                </div>
                {vehicleData.device_id && (
                    <div className="group">
                        <div key="policy_date" className="field">
                            <label htmlFor="policy_date">
                                {t('POLICY_DATE')}
                            </label>
                            <div>
                                <DatePicker
                                    id="policy_date"
                                    locale={'pl'}
                                    autoComplete={'off'}
                                    selected={vehicleData.policy_date}
                                    onChange={(date: Date) => {
                                        setVehicleData({
                                            ...vehicleData,
                                            policy_date: date,
                                        });
                                    }}
                                    dateFormat={DATEPICKER_DATE_FORMAT}
                                    calendarStartDay={1}
                                />
                            </div>
                        </div>
                        <div key="inspection_date" className="field">
                            <label htmlFor="inspection_date">
                                {t('INSPECTION_DATE')}
                            </label>
                            <div>
                                <DatePicker
                                    id="inspection_date"
                                    locale={'pl'}
                                    autoComplete={'off'}
                                    selected={vehicleData.inspection_date}
                                    onChange={(date: Date) => {
                                        setVehicleData({
                                            ...vehicleData,
                                            inspection_date: date,
                                        });
                                    }}
                                    dateFormat={DATEPICKER_DATE_FORMAT}
                                    calendarStartDay={1}
                                />
                            </div>
                        </div>
                        {/*<div key="inspection_distance" className="field">*/}
                        {/*    <label htmlFor="inspection_distance">{t('INSPECTION_DISTANCE')}</label>*/}
                        {/*    {!vehicleData.device_id && <input type="text" id="inspection_distance" name="inspection_distance" value="" readOnly={true}/>}*/}
                        {/*    {vehicleData.device_id && <input*/}
                        {/*        id="inspection_distance" name="inspection_distance"*/}
                        {/*        value={vehicleData.inspection_distance} onChange={setVehicleDataField}*/}
                        {/*        type="number" min={10} max={1000000000} step={1}*/}
                        {/*    />}*/}
                        {/*</div>*/}
                        <div key="diagnostic_date" className="field">
                            <label htmlFor="diagnostic_date">
                                {t('DIAGNOSTIC_DATE')}
                            </label>
                            <div>
                                <DatePicker
                                    id="diagnostic_date"
                                    locale={'pl'}
                                    autoComplete={'off'}
                                    selected={vehicleData.diagnostic_date}
                                    onChange={(date: Date) => {
                                        setVehicleData({
                                            ...vehicleData,
                                            diagnostic_date: date,
                                        });
                                    }}
                                    dateFormat={DATEPICKER_DATE_FORMAT}
                                    calendarStartDay={1}
                                />
                            </div>
                        </div>
                    </div>
                )}
                {currentVehicle.puesc_register_state === 'registered' &&
                    (currentVehicle.puesc_contract_etoll_id !== null ||
                        currentVehicle.puesc_contract_sent_id !== null) &&
                    currentVehicle.puesc_etoll_state_front !== 'error' && (
                        <div className="group">
                            {currentVehicle.puesc_contract_etoll_id !==
                                null && (
                                <CheckboxField
                                    id="puesc_etoll_active"
                                    name="puesc_etoll_active"
                                    label={t('TRANSFER_OF_ETOLL_DATA')}
                                    value={vehicleData.puesc_etoll_active}
                                    onChange={(value) =>
                                        setVehicleData((prev) => ({
                                            ...prev,
                                            puesc_etoll_active: !value,
                                        }))
                                    }
                                />
                            )}
                            {currentVehicle.puesc_contract_sent_id !== null && (
                                <CheckboxField
                                    id="puesc_sent_active"
                                    name="puesc_sent_active"
                                    label={t('TRANSFER_OF_SENT_DATA')}
                                    value={vehicleData.puesc_sent_active}
                                    onChange={(value) =>
                                        setVehicleData((prev) => ({
                                            ...prev,
                                            puesc_sent_active: !value,
                                        }))
                                    }
                                />
                            )}
                        </div>
                    )}
            </>
        );
    };

    const Footer = () => (
        <>
            <button
                type="reset"
                className="button edit"
                onClick={(e) => {
                    e.preventDefault();
                    paneRef.current.hideComponent();
                }}
            >
                {t('common:CANCEL')}
            </button>
            <button disabled={updateInProgress} className="button save">
                {t('SAVE')}
            </button>
        </>
    );

    return (
        <form id="vehicle-edit-form" onSubmit={updateVehicle}>
            <RightPane
                ref={paneRef}
                id="vehicle-edit"
                className="vehicle-edit panel-right-form panel-right-entity-details"
                title={t('EDIT_VEHICLE')}
                onComponentHidden={onHide}
                body={() =>
                    updateInProgress ? <Loader /> : vehicleDataFields()
                }
                footer={Footer}
            />
        </form>
    );
};

export default EditVehicle;
