import React from 'react';
import {useTranslation} from 'react-i18next';
import RightPane from '../../../components/RightPane/RightPaneFunc';
import {IconArchive, IconEdit} from '../../../graphics/icons';
import {PickupForm} from '../FormManager';
import './FormPreviewEditor.scss';

interface FormPreviewEditorProps {
    isOpen: boolean;
    onClose: () => void;
    onEdit: () => void;
    deleteForm: () => void;
    form: PickupForm | null;
}

const FormPreviewEditor: React.FC<FormPreviewEditorProps> = ({
    form,
    isOpen,
    onClose,
    onEdit,
    deleteForm,
}) => {
    const [t] = useTranslation(['Forms', 'common']);

    const renderHeaderButtons = () => (
        <>
            <button
                className="button action icon"
                onClick={onEdit}
                data-tip={t('EDIT_FORM')}
                data-place="left"
            >
                <IconEdit />
            </button>
            <button
                className="button edit icon"
                onClick={deleteForm}
                data-tip={t('DELETE_FORM')}
                data-place="left"
            >
                <IconArchive />
            </button>
        </>
    );

    const renderField = (field: PickupForm['fields'][0], index: number) => (
        <div key={index} className="form-preview-editor__field">
            <div className="form-preview-editor__field-label">
                {field.label} -
            </div>
            <div className="form-preview-editor__field-description">
                {field.description}
            </div>
        </div>
    );

    return (
        <RightPane
            id="form-preview-editor"
            title={form?.name || t('FORM_PREVIEW')}
            buttons={renderHeaderButtons}
            isOpen={isOpen}
            onClose={onClose}
            body={() => (
                <div className="form-preview-editor">
                    <h3 className="form-preview-editor__header">
                        {t('LIST_TO_CHECK')}
                    </h3>
                    <div className="form-preview-editor__fields">
                        {form?.fields?.map(renderField)}
                    </div>
                </div>
            )}
        />
    );
};

export default FormPreviewEditor;
