import {
    ADD_CHAT_USER,
    ADD_MESSAGE,
    MARK_MESSAGE,
    SET_CHAT_USER_DETAILS,
} from '../actions/chatUserActions';

const initialState = [];

export default function chatUserList(state = initialState, action) {
    switch (action.type) {
        case ADD_CHAT_USER: {
            let index = state.findIndex(
                (user) => user.id === action.userData.id,
            );
            if (index === -1) {
                Object.assign(action.userData, {messages: []});
                return [...state, action.userData];
            }
            return state;
        }
        case SET_CHAT_USER_DETAILS: {
            return state.map((user) => {
                if (user.id !== action.userData.id) {
                    return user;
                } else {
                    return Object.assign({}, user, action.userData);
                }
            });
        }
        case ADD_MESSAGE: {
            return state.map((user) => {
                if (
                    user.id !== action.message.id_from &&
                    user.id !== action.message.id_to
                ) {
                    return user;
                } else {
                    return Object.assign(user, {
                        messages: user.messages.concat(action.message),
                    });
                }
            });
        }
        case MARK_MESSAGE: {
            return state.map((user) => {
                if (
                    user.id !== action.log.id_to &&
                    user.id !== action.log.id_msg_from
                ) {
                    return user;
                } else {
                    return {
                        ...user,
                        messages: user.messages.map((message) => {
                            if (message.id === action.log.id) {
                                return {
                                    ...message,
                                    log: [...(message.log || []), {
                                        type: action.log.type,
                                        id_from: action.log.id_from,
                                    }]
                                };
                            }
                            return message;
                        })
                    };
                }
            });
        }
        default: {
            return state;
        }
    }
}
