import React, {useRef} from 'react';
import {useTranslation} from 'react-i18next';
import {toast} from 'react-toastify';
import moment from 'moment';

import useServiceProvider from '../../../utils/service';
import RightPane from '../../../components/RightPane/RightPaneFunc';
import {IconArchive} from '../../../graphics/icons';
import {FULL_DATE_WITHOUT_SECONDS_FORMAT} from '../../../utils/constants';
import type {Form} from '../../../utils/interfaces/form';

interface FormPreviewProps {
    form: Form | null;
    isOpen: boolean;
    onClose: () => void;
    doVerify?: () => void;
}

export default function FormPreview({
    form,
    onClose,
    doVerify,
    isOpen,
}: FormPreviewProps): JSX.Element {
    const {t} = useTranslation('Forms');
    const paneRef = useRef<HTMLDivElement>(null);
    const {vehicleService} = useServiceProvider();

    function getFormTitle(): string {
        if (form?.vehicle) {
            return form.vehicle.name;
        } else {
            return t('FORM_PREVIEW');
        }
    }

    function alphabetical(a: [string, string], b: [string, string]): number {
        if (a[0].toLowerCase() < b[0].toLowerCase()) return -1;
        if (a[0].toLowerCase() > b[0].toLowerCase()) return 1;
        return 0;
    }

    function prepareFormData(): [string, string][] {
        if (!form) return [];
        const formChecklist = JSON.parse(form.form_data as string);
        let formChecklistArray = Object.entries(formChecklist) as [
            string,
            string,
        ][];
        return formChecklistArray.sort(alphabetical);
    }

    function getPaneBody(): JSX.Element {
        let formData = prepareFormData();

        return (
            <>
                <div className="group">
                    <dl>
                        <dt>{t('VEHICLE_NAME')}</dt>
                        <dd>{form?.vehicle?.name}</dd>
                        <dt>{t('SUBMITTED_BY')}</dt>
                        <dd>{form?.submitted_by_name}</dd>
                        <dt>{t('SUBMITTED_AT')}</dt>
                        <dd>
                            {form?.submitted_at
                                ? moment
                                      .unix(form.submitted_at)
                                      .format(FULL_DATE_WITHOUT_SECONDS_FORMAT)
                                : ''}
                        </dd>
                        <dt>{t('VERIFIED_BY')}</dt>
                        <dd>{form?.verified_by_name}</dd>
                        <dt>{t('VERIFIED_AT')}</dt>
                        <dd>
                            {form?.verified_at
                                ? moment
                                      .unix(form.verified_at)
                                      .format(FULL_DATE_WITHOUT_SECONDS_FORMAT)
                                : ''}
                        </dd>
                        {form?.comment ? (
                            <>
                                <dt>{t('COMMENT')}</dt>
                                <dd>{form.comment}</dd>
                            </>
                        ) : (
                            ''
                        )}
                    </dl>
                </div>
                {doVerify && (
                    <div className="group">
                        <button className="button action" onClick={doVerify}>
                            {t('VERIFY_FORM')}
                        </button>
                    </div>
                )}
                <div className={'form-body group'}>
                    {formData.map((item, key) => (
                        <div className="form-item" key={'item_' + key}>
                            <div className={'form-item-text'}>{item[0]}</div>
                            <div
                                className={
                                    'form-item-text value ' +
                                    (item[1] === '0' ? 'null' : '')
                                }
                            >
                                {item[1] === '1' ? 'OK' : t('LACK')}
                            </div>
                        </div>
                    ))}
                </div>
            </>
        );
    }

    function archiveForm(e: React.MouseEvent<HTMLButtonElement>): void {
        e.preventDefault();
        if (!form) return;
        const signalSuccess = () => {
            toast.success(t('FORM_ARCHIVED'));
            window.dispatchEvent(
                new CustomEvent('form_archived', {detail: form.id}),
            );
            onClose();
        };
        const signalFailure = (reason: string) =>
            toast.error(t('FORM_NOT_ARCHIVED', {error: t(reason)}));
        vehicleService.archiveForm(form.id, signalSuccess, (reason: string) => {
            reason === 'err_already_archived'
                ? signalSuccess()
                : signalFailure(reason);
        });
    }

    return (
        <RightPane
            ref={paneRef}
            id="form-preview"
            className="form-preview panel-right-entity-details"
            title={getFormTitle()}
            isOpen={isOpen}
            onClose={onClose}
            body={getPaneBody}
            buttons={
                form?.state !== 'archived'
                    ? () => (
                          <button
                              className="button edit icon"
                              data-tip={t('ARCHIVE_FORM')}
                              onClick={archiveForm}
                          >
                              <IconArchive />
                          </button>
                      )
                    : undefined
            }
        />
    );
}
